import React, { useRef } from 'react';
import { graphql } from 'gatsby';

import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

import SEO from '../components/SEO';
import ScrollSection from '../components/ScrollSection';
import ScienceIntro from '../components/ScienceIntro';
import Footer from '../components/Footer';
import EthicsItemSection from '../components/EthicsDetailedItem';

gsap.registerPlugin(ScrollToPlugin);

const EthicsPage = ({ data }) => {
  const {
    //   metaDescription,
    introTitle,
    fullSpectrumText,
    fullSpectrumImage,
    exploringEmotionImage,
    exploringEmotionSubheading,
    exploringEmotionText,
    researchText,
    researchTitle,
  } = data.allContentfulPageEthics.nodes[0];

  const nextSectionRef = useRef();

  // const scrollToNext = () => {
  //   if (window.innerWidth <= breakpoints.tabletL) {
  //     if (nextSectionRef.current) {
  //       gsap.to(window, {
  //         scrollTo: nextSectionRef.current.offsetTop,
  //         duration: 1,
  //       })
  //     }
  //   } else {
  //     if (window.scroll.scrollTo) {
  //       if (nextSectionRef.current) {
  //         window.scroll.scrollTo(nextSectionRef.current)
  //       }
  //     }
  //   }
  // }

  // const [current, setCurrent] = useState(0)

  // const findingsSectionRef = useRef()

  return (
    <>
      <SEO
        title="Ethics"
        // description={metaDescription.metaDescription}
        pathname={`ethics`}
      />

      <ScrollSection>
        <ScienceIntro
          // title={``}
          title={introTitle}
          buttonText={`The Hume Initiative`}
          buttonTo={`/`}
          next={nextSectionRef}
          ethicsHero
        />
      </ScrollSection>
      <ScrollSection>
        <EthicsItemSection
          text={fullSpectrumText}
          image={fullSpectrumImage}
          align={`left`}
          bold
          index={0}
          ref={nextSectionRef}
        />
      </ScrollSection>
      <ScrollSection>
        <EthicsItemSection
          subheading={exploringEmotionSubheading}
          text={exploringEmotionText}
          image={exploringEmotionImage}
          align={`right`}
          index={1}
        />
      </ScrollSection>
      <ScrollSection>
        <ScienceIntro
          title={researchTitle}
          text={researchText}
          buttonText={`The Hume Initiative`}
          buttonTo={`/`}
          guidelines
        />
      </ScrollSection>

      <Footer />
    </>
  );
};

export default EthicsPage;

export const pageQuery = graphql`
  query EthicsPageQuery {
    allContentfulPageEthics {
      nodes {
        title
        introTitle
        fullSpectrumText {
          raw
        }

        fullSpectrumImage {
          fluid(maxWidth: 900) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }

        exploringEmotionImage {
          fluid(maxWidth: 1100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        exploringEmotionSubheading
        exploringEmotionText {
          raw
        }
        researchTitle
        researchText
        guidelinesButtonText
      }
    }
  }
`;
