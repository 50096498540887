import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { font } from '../../styles/vars/font.style';
import { mq } from '../../styles/vars/media-queries.style';
// import { TextStyles } from '../../styles/vars/textStyles.style'

export const ScienceItem = styled.div`
  opacity: 1;
  margin-top: -1px;
  pointer-events: all;
  transition: opacity 0.4s ${easings.inOut.cubic} 0.4s;
  height: 100%;

  ${({ index }) =>
    index === 0
      ? clamp('padding-top', 60, 120)
      : clamp('padding-top', 120, 210)}

  ${({ index }) =>
    index === 1
      ? `
  ${clamp('padding-bottom', 120, 130)}`
      : ``}

  ${mq.tabletL} {
    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  ${({ align }) =>
    align === 'right'
      ? `> div {
       > div {
         display: flex;
         flex-direction: column;

         ${mq.tabletL} {
               display: grid;
             }

         >div {
           :first-of-type {
             order: 2;
             ${mq.tabletL} {
               order: 1
             }
           }
           :last-of-type {
             order: 1;
${mq.tabletL} {
               order: 2
             }
           }
         }
       }
     }`
      : `> div {
       > div {
         display: flex;
         flex-direction: column;

           ${mq.tabletL} {
               display: grid;
             }

         >div {
           :first-of-type {
             order: 1;

             ${mq.tabletL} {
               order: 1
             }
           }
           :last-of-type {
             order: 2;

             ${mq.tabletL} {
               order: 2
             }
           }
         }
       }
     }`}
`;

export const ScienceItemLeft = styled.div`
  height: 100%;
  text-align: left;
  padding-top: 8.8rem;
  position: relative;

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 0rem;
    padding-left: 6.25%;
    padding-right: 6.25%;
  }

  img {
    object-fit: cover;
    width: 100%;
  }
`;

export const ScienceItemRight = styled.div`
  text-align: left;
  transition: opacity 0.8s ${easings.out.quint};
  z-index: 3;

  ${({ bold }) => {
    return bold === true
      ? `p {font-family: ${font.primary};
  font-weight: ${font.weight.medium};
  line-height: 1.16;
  letter-spacing: -0.01em;
  ${clamp('font-size', 26, 28)}
      }`
      : ``;
  }}

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    pointer-events: all;
    position: relative;
    text-align: left;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    :last-of-type {
      > span {
        margin-bottom: 0;
      }
    }

    > span {
      margin-bottom: 3rem;
    }
  }

  a {
    margin-top: 3.4rem;
  }
`;

export const ScienceItemImageWrap = styled.div`
  border-radius: 2rem;
  overflow: hidden;
  position: relative;

  /* :before {
    /* border-radius: 2rem; */
    /* border: 0.1rem solid ${colors.light}; */
    /* content: '';
    height: calc(100% - (2 * 1.3rem));
    left: 1.3rem;
    position: absolute;
    top: 1.3rem;
    width: calc(100% - (2 * 1.3rem));
    z-index: 2;

    ${mq.tabletL} {
      height: calc(100% - (2 * 2.2rem));
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: calc(100% - (2 * 2.2rem));
    }
  } */ */
`;
