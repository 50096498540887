import React from 'react';
import Img from 'gatsby-image';
import { Heading3, TextCaption } from '../TextStyles';

import {
  ScienceItem,
  ScienceItemLeft,
  ScienceItemRight,
  ScienceItemImageWrap,
} from './index.style';
import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import RichText from '../RichText';

import AnimateImageIn from '../_animation/AnimateImageIn';
import AnimateSplitText from '../_animation/AnimateSplitText';

const EthicsItemSection = React.forwardRef((props, ref) => {
  const { index, subheading, heading, text, image, align, bold } = props;

  const copy = <RichText content={text} delay={0.4} />;

  return (
    <>
      {align === 'right' ? (
        <ScienceItem align={align} index={index} ref={ref}>
          <Container>
            <Grid colCount={16} align={align}>
              <GridItem tabletL={7} tabletLStart={2} desk={6} deskStart={3}>
                <ScienceItemLeft>
                  {image ? (
                    <ScienceItemImageWrap>
                      <AnimateImageIn delay={0} transparent>
                        <Img fluid={image.fluid} />
                      </AnimateImageIn>
                    </ScienceItemImageWrap>
                  ) : (
                    ``
                  )}
                </ScienceItemLeft>
              </GridItem>

              <GridItem tabletL={6} tabletLStart={10} desk={5} deskStart={10}>
                <ScienceItemRight bold={bold}>
                  {subheading ? (
                    <TextCaption bold>
                      <AnimateSplitText>{subheading}</AnimateSplitText>
                    </TextCaption>
                  ) : (
                    ``
                  )}
                  {heading ? (
                    <Heading3>
                      <AnimateSplitText
                        delay={0.2}
                        type={`lines`}
                        heading={true}
                      >
                        {heading}
                      </AnimateSplitText>
                    </Heading3>
                  ) : (
                    ``
                  )}
                  {copy}
                </ScienceItemRight>
              </GridItem>
            </Grid>
          </Container>
        </ScienceItem>
      ) : (
        <ScienceItem align={align} index={index} ref={ref}>
          <Container>
            <Grid colCount={16}>
              <GridItem tabletL={6} tabletLStart={3} desk={5} deskStart={4}>
                <ScienceItemRight bold={bold}>
                  {subheading ? (
                    <TextCaption bold>
                      <AnimateSplitText>{subheading}</AnimateSplitText>
                    </TextCaption>
                  ) : (
                    ``
                  )}
                  {heading ? (
                    <Heading3>
                      <AnimateSplitText
                        delay={0.2}
                        type={`lines`}
                        heading={true}
                      >
                        {heading}
                      </AnimateSplitText>
                    </Heading3>
                  ) : (
                    ``
                  )}

                  {copy}
                </ScienceItemRight>
              </GridItem>

              <GridItem tabletL={5} tabletLStart={10} desk={4} deskStart={10}>
                <ScienceItemLeft>
                  {image ? (
                    <ScienceItemImageWrap>
                      <AnimateImageIn delay={0} transparent>
                        <Img fluid={image.fluid} />
                      </AnimateImageIn>
                    </ScienceItemImageWrap>
                  ) : (
                    ``
                  )}
                </ScienceItemLeft>
              </GridItem>
            </Grid>
          </Container>
        </ScienceItem>
      )}
    </>
  );
});

export default EthicsItemSection;
